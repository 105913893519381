
firebase.initializeApp({
  apiKey: "AIzaSyBcMH36ztCuSR-95O4KrIExvXinMdgUR8k",
  authDomain: "biggest-fan-site.firebaseapp.com",
  databaseURL: "https://biggest-fan-site.firebaseio.com",
  projectId: "biggest-fan-site",
  storageBucket: "biggest-fan-site.appspot.com",
  messagingSenderId: "871968104501",
  appId: "1:871968104501:web:5c5780d6e29ea387df117c",
  measurementId: "G-LBTJ5HTCC9",
});
firebase.analytics();

const body = document.querySelector("body");
const config = { childList: true, subtree: true };

const callback = function (mutationsList, observer) {

  // TO DO refactor these lines of code
  const videoContainer = document.querySelector("#video-container");
  const videoIframe = document.querySelector("#video-iframe");
  const videoOverlay = document.querySelector("#video-overlay");
  const fireStore = firebase.firestore();
  const signUpButton = document.querySelector(".sign-up-btn");
  const mobileSignUpButton = document.querySelector(".mobile-sign-up-btn");
  const overlayContainer = document.querySelector(".overlay-container");
  const overlay = document.querySelector(".overlay");
  const signUpModal = document.querySelector(".sign-up-modal");
  const closeButton = document.querySelector(".close-sign-up-button");
  const signUpFormContainer = document.querySelector(".sign-up-form-container");
  const signUpForm = document.querySelector(".sign-up-form");
  const emailInput = document.querySelector(".email-input");
  const subscribeBtn = document.querySelector(".subscribe-btn");
  const checkMark = document.querySelector(".check-mark");
  const mobileMenuButton = document.querySelector(".mobile-menu-button");
  const mobileMenuContainer = document.querySelector(".mobile-menu-container");
  const mobileMenuCloseButton = document.querySelector(".mobile-menu-close-button");

  if (!!videoContainer
    && !!videoIframe
    && !!videoOverlay) {

    if (videoOverlay.getAttribute('listener') !== 'true') {
      videoOverlay.addEventListener("click", () => {
        videoOverlay.setAttribute('listener', 'true');
        videoContainer.classList.add("hidden");
        videoIframe.src = "";
      });
      document.querySelector("#laptop-img").addEventListener("click", () => {
        videoIframe.src =
          "https://www.youtube-nocookie.com/embed/byUEKnd9J-U?autoplay=0";
        videoContainer.classList.remove("hidden");
      });
    }
  }

  if (!!mobileMenuButton
    && !!mobileMenuContainer) {

    if (mobileMenuButton.getAttribute('listener') !== 'true') {
      mobileMenuButton.addEventListener("click", function () {
        mobileMenuButton.setAttribute('listener', 'true');
        mobileMenuContainer.classList.remove("hidden");
      });
    }
  }

  if (!!mobileMenuContainer
    && !!mobileMenuCloseButton) {

    if (mobileMenuCloseButton.getAttribute('listener') !== 'true') {
      mobileMenuCloseButton.addEventListener("click", function () {
        mobileMenuCloseButton.setAttribute('listener', 'true');
        mobileMenuContainer.classList.add("hidden");
      });
    }
  }

  if (!!fireStore
    && !!signUpButton
    && !!mobileSignUpButton
    && !!overlayContainer
    && !!overlay
    && !!signUpModal
    && !!closeButton
    && !!signUpFormContainer
    && !!signUpForm
    && !!emailInput
    && !!subscribeBtn
    && !!checkMark) {

    if (mobileSignUpButton.getAttribute('listener') !== 'true') {
      mobileSignUpButton.addEventListener("click", function () {
        mobileSignUpButton.setAttribute('listener', 'true');
        overlayContainer.classList.remove("hidden");
        overlay.classList.remove("opacity-0");
        overlay.classList.add("ease-out", "duration-300", "opacity-100");
        signUpModal.classList.remove("opacity-0");
        signUpModal.classList.add("ease-out", "duration-300", "opacity-100");
        signUpFormContainer.classList.remove("invisible");
        checkMark.classList.add("hidden");
      });
    }
    if (signUpButton.getAttribute('listener') !== 'true') {
      signUpButton.addEventListener("click", function () {
        signUpButton.setAttribute('listener', 'true');
        overlayContainer.classList.remove("hidden");
        overlay.classList.remove("opacity-0");
        overlay.classList.add("ease-out", "duration-300", "opacity-100");
        signUpModal.classList.remove("opacity-0");
        signUpModal.classList.add("ease-out", "duration-300", "opacity-100");
        signUpFormContainer.classList.remove("invisible");
        checkMark.classList.add("hidden");
      });
    }
    if (closeButton.getAttribute('listener') !== 'true') {
      closeButton.addEventListener("click", function () {
        closeButton.setAttribute('listener', 'true');
        overlay.classList.remove("ease-out", "duration-300", "opacity-100");
        overlay.classList.add("opacity-0", "ease-in", "duration-200");
        signUpModal.classList.remove("ease-out", "duration-300", "opacity-100");
        signUpModal.classList.add("opacity-0", "ease-in", "duration-200");
        overlayContainer.classList.add("hidden");
        signUpForm.reset();
      });
    }
    if (emailInput.getAttribute('listener') !== 'true') {
      emailInput.addEventListener("input", function (e) {
        if (signUpForm.reportValidity()) {
          subscribeBtn.classList.remove("disabled");
          subscribeBtn.classList.add("enabled");
        } else {
          subscribeBtn.classList.remove("enabled");
          subscribeBtn.classList.add("disabled");
        }
      });
      emailInput.addEventListener("invalid", function (e) {
        e.preventDefault();
      });
      emailInput.setAttribute('listener', 'true');
    }
    if (signUpForm.getAttribute('listener') !== 'true') {
      signUpForm.addEventListener("submit", function (e) {
        signUpForm.setAttribute('listener', 'true');
        e.preventDefault();
        fireStore
          .collection("email_subscribed")
          .add({
            email: emailInput.value,
            time: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            signUpFormContainer.classList.add("invisible");
            checkMark.classList.remove("hidden");
          });
      });
    }
  }

  if (!!videoContainer
    && !!videoIframe
    && !!videoOverlay
    && !!fireStore
    && !!signUpButton
    && !!mobileSignUpButton
    && !!overlayContainer
    && !!overlay
    && !!signUpModal
    && !!closeButton
    && !!signUpFormContainer
    && !!signUpForm
    && !!emailInput
    && !!subscribeBtn
    && !!checkMark
    && !!mobileMenuButton
    && !!mobileMenuContainer
    && !!mobileMenuCloseButton) {

    observer.disconnect();
  }
};

const observer = new MutationObserver(callback);

observer.observe(body, config);
